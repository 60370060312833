<template>
  <section class="publish-form-section">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Maisons d'édition
            <v-spacer></v-spacer>
            <v-btn color="info" @click="openDialog">Ajouter une maison d'édition</v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="publishingHouses" class="elevation-1" :search="search" :loading="loading">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field v-model="search" label="Recherche"></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-icon class="icon-edit" @click="editPublishingHouse(item)">mdi-pencil</v-icon>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-icon class="icon-delete" @click="confirmDeletePublishingHouse(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon class="icon-view" @click="$router.push({ name: 'BooksPage', params: { publishingHouseId: item.id } })">
                  mdi-book-open-page-variant
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Main Form Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="publishingHouse.name" label="Name" outlined></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialog">Annuler</v-btn>
          <v-btn color="info" text @click="savePublishingHouse">
  {{ formTitle.includes('Ajouter') ? 'Ajouter' : 'Modifier' }}
</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <!-- Confirmation Dialog -->
     <v-row justify="center">
      <v-dialog v-model="confirmDialog" max-width="620">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer cette maison d'édition?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
             @click="cancelDelete"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="deletePublishingHouse">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</section>
</template>

<script>
import axios from 'axios';
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        { text: 'Nom', value: 'name' },
        { text: 'Naviguer', value: 'actions', sortable: false },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
      ],
      publishingHouses: [],
      dialog: false,
      confirmDialog: false,
      formTitle: 'Ajouter une maison d édition',
      publishingHouse: {
        id: null,
        name: '',
      },
      deleteId: null, // For tracking the id of the item to be deleted
    };
  },
  created() {
    this.fetchPublishingHouses();
  },
  methods: {
    fetchPublishingHouses() {
      axios.get(Constant.QORANI + `library/publishHouse/getall`)
        .then(response => {
          this.publishingHouses = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    searchPublishingHouses() {
      // Add your search logic here if needed
    },
    openDialog() {
      this.resetForm();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.formTitle = 'Ajouter une maison d édition';
      this.publishingHouse = { id: null, name: '' };
    },
    savePublishingHouse() {
      if (this.publishingHouse.id) {
        axios.post(Constant.QORANI + `library/publishHouse/edit/${this.publishingHouse.id}`, this.publishingHouse)
          .then(() => {
            this.fetchPublishingHouses();
            this.closeDialog();
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        axios.post(Constant.QORANI + `library/publishHouse/add`, this.publishingHouse)
          .then(() => {
            this.fetchPublishingHouses();
            this.closeDialog();
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    editPublishingHouse(publishingHouse) {
      this.formTitle = 'Modifier la maison d édition';
      this.publishingHouse = { ...publishingHouse };
      this.dialog = true;
    },
    confirmDeletePublishingHouse(publishingHouse) {
      this.deleteId = publishingHouse.id;
      this.confirmDialog = true;
    },
    cancelDelete() {
      this.confirmDialog = false;
      this.deleteId = null;
    },
    deletePublishingHouse() {
      axios.get(Constant.QORANI + `library/publishHouse/delete/${this.deleteId}`)
        .then(() => {
          this.fetchPublishingHouses();
          this.confirmDialog = false;
          this.deleteId = null;
        })
        .catch(error => {
          console.error(error);
        });
    },
  }
};
</script>

<style scoped>
/* Base styles for the publishing house section */
.publish-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.v-container {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling */
.v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* White background for cards */
}

/* Button styling */
.v-btn {
  border-radius: 6px;
  font-weight: 500;
}

/* Data table styling */
.elevation-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

.elevation-1 thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
}

/* Icon styling */
.icon-edit,
.icon-delete,
.icon-view {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.icon-edit:hover {
  color: #1976d2; /* Change color on hover */
}

.icon-delete:hover {
  color: #d32f2f; /* Change color on hover */
}

.icon-view:hover {
  color: #388e3c; /* Change color on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .v-container {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-card {
    margin-bottom: 15px; /* Adjusted margin for cards */
  }

  .v-btn {
    width: 100%; /* Full width buttons on small screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables on small screens */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 100%; /* Allow full width on larger screens */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for larger screens */
  }
}
</style>